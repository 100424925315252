import React, {useState, useEffect} from 'react'
import './Event.css';
import Moment from 'react-moment'
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import Navigation from './Navigation.js'
import Image from 'react-bootstrap/Image'
import ReactGA from 'react-ga4'

export default function Event(props)
{
    
    let tempEventID = '';
    if (props.location.eventProps !== undefined){
        tempEventID = props.location.eventProps.eventID
        localStorage.setItem('currEvent',tempEventID)
    }
    else{
        tempEventID = localStorage.getItem('currEvent')
    }
    const [eventid] = useState(tempEventID);

    const [event,setEvent] = useState([])
    // const [eventid] = useState(props.location.eventProps.eventID);
    const [imgurl, setImgurl] = useState("")

    let thisEvent = {
        title: event.title,
        description: event.description,
        location: event.locations,
        startTime: event.start,
        endTime: event.end
    };

    let thisIcon = {'calendar-plus-o':'left'}

    useEffect(() => { 

        const events = JSON.parse(localStorage.getItem('events'))
         for (var i=0 ; i < events.length ; i++)
        {
            if (events[i]['id'] === eventid) {
                setEvent(events[i])
                setImgurl('https://www.icpl.org'+events[i]['image'])
            }
        }
        // fetch("https://www.icpl.org/new-api/events/"+eventid)
        //     .then(response => response.json())
        //     .then(data =>{
        //         console.log(data)
        //         setEvent(data)
        //         if (data.image.length > 0){
        //             setImgurl('https://www.icpl.org'+data.image)
        //         }
        //     })
    },[eventid])

    function Event(linkText, linkUrl){
        ReactGA.event('event_click', {
            link_text: linkText,
            link_url: linkUrl,
        });
    };

    if(imgurl.length > 0){
        let registerUrl = "https://www.icpl.org/node/"+event.id+"/register#main-content"
        return(
            <div>
                <Navigation location='/event' />
                <div className="eventMain">
                    {imgurl!=='https://www.icpl.org' ? <Image src={imgurl} alt="event" fluid /> : ""}
                    <h1 className="eventPgTitle">{event.title}</h1>
                    <p>
                        <Moment format="dddd, MMMM D, YYYY">{event.start}</Moment><br/>
                        <Moment format="h:mm a">{event.start}</Moment> to
                        <Moment format=" h:mm a">{event.end}</Moment><br/>
                        {event.locations}
                    </p>
                    <AddToCalendar event={thisEvent} buttonTemplate={thisIcon}/><br /><br />
                    <div>{( event.registration 
                        ? <div className="event-registration">
                            <a href={registerUrl} className="btn btn-primary" onClick={()=>Event('Register', {registerUrl})}>Register</a> <span className="text-danger">Registration required</span></div>
                        : '' )}</div>
                    {event.description}
                </div>
            </div>
        )
    }
    else{
        return(
            <div>
                <Navigation location='/event' />
                <div className="eventMain">
                    <h3>{event.title}</h3>
                    <div>
                        <Moment format="dddd, MMMM Do - h:mm a">{event.start}</Moment> to
                        <Moment format=" h:mm a">{event.end}</Moment>
                    </div>
                    <br />
                    <AddToCalendar event={thisEvent} /><br /><br />
                    {event.description}
                </div>
            </div>
        )       
    }
}