import React from 'react'

function Social()
{
    return(
        <div>
            <h1>Social Sites</h1>
        </div>
    )
}

export default Social