import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { TYPE_OF_THEME } from './enum';

const LightTheme = React.lazy(() => import('./themes/lightTheme'));
const DarkTheme = React.lazy(() => import('./themes/darkTheme'));

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = localStorage.getItem('theme');
  // const CHOSEN_THEME = localStorage.getItem('theme') || TYPE_OF_THEME.DEFAULT;
  return (
    <>
      <React.Suspense fallback={<></>}>
      {(CHOSEN_THEME === 'light') && <LightTheme />}
        {(CHOSEN_THEME === 'dark') && <DarkTheme />}
        {/* {(CHOSEN_THEME === TYPE_OF_THEME.LIGHT_MODE) && <LightTheme />}
        {(CHOSEN_THEME === TYPE_OF_THEME.DARK_MODE) && <DarkTheme />} */}
      </React.Suspense>
      {children}
    </>
  )
}
ReactDOM.render(
  <React.StrictMode>
    <ThemeSelector>
    <Router>
      <App />
    </Router>
    </ThemeSelector>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
