import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import './Blogposts.css';

export default function Blogposts()
{
    const [posts, setPosts] = useState([])

    useEffect(() => { 
        // // while(localStorage.getItem('posts') == null){
        // //     //do nothing
        // //     console.log("wating...")
        // // }
        // // console.log(localStorage.getItem('posts'))
        // // console.log(JSON.parse(localStorage.getItem('posts')))
        // setPosts(JSON.parse(localStorage.getItem('posts')))
   
        // console.log("In blogposts.js")
        if (localStorage.getItem('posts') !== null){
            setPosts(JSON.parse(localStorage.getItem('posts')))
        }
        else{
            fetch("https://www.icpl.org/new-api/news?per_page=5")
            .then(response => response.json())
            .then(data =>{
                    //display details
                    console.log(data)
                    setPosts(data)
                    localStorage.setItem('posts', JSON.stringify(data))
            })
        }
    },[])

    return(
        <div>
            <h2>ICPL Staff Blog Posts</h2>
                {
                    posts.map((post) => (

                        <Link className="link" key={post.id} to={{
                            pathname: './blogpost',
                            blogpostProps: {
                                postID: post.id,
                            }
                        }} >                         
                            <Card className='blogpostsCard'>
                                <Card.Body>
                                    {post.title.rendered}
                                </Card.Body>
                            </Card>
                        </Link>
                    ))
                }
        </div>
    )
}