import React from 'react';
import {Navbar, NavDropdown, Nav} from 'react-bootstrap';
// import {Link, Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './Navigation.css';
import Drawer from './Drawer.js'

//import react-icons
import {VscHome} from 'react-icons/vsc';
import {HiMenu} from 'react-icons/hi';
import {FaRegAddressCard} from 'react-icons/fa';
import {GoSearch} from 'react-icons/go';
// import {BiBarcodeReader} from 'react-icons/bi';
// import {BsPeople} from 'react-icons/bs';
import {IoMdChatboxes} from 'react-icons/io';
import {BsCalendar} from 'react-icons/bs';
import {HiOutlineClipboardList} from 'react-icons/hi';
// import {RiBookOpenLine} from 'react-icons/ri';
import {BsGear} from 'react-icons/bs';
import {IoIosArrowBack} from 'react-icons/io';
// import { Backdrop } from '@material-ui/core';
import ReactGA from 'react-ga4'

function Navigation(props) {
    console.log(props)
    // let history = useHistory();
    let history = createBrowserHistory();

    history.listen(({ action, location }) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      );
      console.log(`The last navigation action was ${action}`);
    });

    let showBack = false
    let back = ''
    let backName = ''

    function Event(linkText, appLocation){
      ReactGA.event('header_click', {
        link_text: linkText,
        app_location: appLocation,
      });
    };

    if(props.location === '/' || props.location === '/home'){
      showBack = false;
      back = '/home';
      backName = 'Home'
    }
    else if (props.location === '/accounts' || props.location === '/blogpost' || 
              props.location === '/events' || props.location === '/contact' || 
              props.location === '/search' || props.location === '/feedback' ||
              props.location === '/settings'){
      showBack = true;
      back = '/home';
      backName = 'Home'
    }
    else if (props.location === '/account'){
      showBack = true;
      back = '/accounts';
      backName = 'Accounts'
    }
    else if (props.location === '/editAccount'){
      showBack = true;
      back = '/account';
      backName = 'Account'
    }
    else if (props.location === '/add'){
      showBack = true;
      back = '/accounts';
      backName = 'Accounts'
    }
    else if (props.location === '/edit'){
      showBack = true;
      back = '/accounts';
      backName = 'Accounts'
    }
    else if (props.location === '/event'){
      showBack = true;
      back = '/events';
      backName = 'Events'
    }
    return (
      <div>
          <Navbar variant="dark">
          <Nav.Item id='back' style={{ visibility: showBack ? 'visible': 'hidden'}}>
              <Nav.Link href={back} onClick={()=>Event('Back', props.location)}><IoIosArrowBack size={28}/><span className="v-middle">{backName}</span></Nav.Link>
          </Nav.Item>
          <Navbar.Brand href="/" onClick={()=>Event('Logo', props.location)}><img src="https://www.icpl.org/sites/default/files/icons/head-logo-img.png" alt="" style={{ maxWidth: 40 }}/> MyICPL</Navbar.Brand>
          {/* <Navbar.Brand><Link to="/">YourICPL</Link></Navbar.Brand> */}
          
          {/* <NavDropdown title={<HiMenu />} id="myicpl-dropdown" variant="dark" aria-label="Menu"> */}
              {/* Works but is reloading full app instead of just component */}
              {/* <NavDropdown.Item href="/"><VscHome /> Home</NavDropdown.Item>
              <NavDropdown.Item href="/accounts"><FaRegAddressCard /> Accounts</NavDropdown.Item>
              <NavDropdown.Item href="/search"><GoSearch /> Search</NavDropdown.Item>
              <NavDropdown.Item href="/events"><BsCalendar /> Events</NavDropdown.Item>
              <NavDropdown.Item href="/contact"><IoMdChatboxes /> Contact</NavDropdown.Item>
              <NavDropdown.Item href="/feedback"><HiOutlineClipboardList /> Feedback</NavDropdown.Item>
              <NavDropdown.Item href="/settings"><BsGear /> Settings</NavDropdown.Item> */}

              {/* Works with errors about nested a tags */}
              {/* <NavDropdown.Item><Link to="/"><VscHome /> Home</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/accounts"><BsPerson /> Accounts</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/search"><GoSearch /> Search</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/scan"><BiBarcodeReader /> Scan</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/social"><BsPeople /> Social</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/contact"><BsClock /> Contact</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/events"><BsCalendar /> Events</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/feedback"><HiOutlineClipboardList /> Feedback</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/accounts"><BsPerson /> Accounts</Link></NavDropdown.Item> */}

              {/* Works without errors but menu does not go away on click */}
              {/* <Nav.Link as={Link} to="/"><VscHome /> Home</Nav.Link>
              <Nav.Link as={Link} to="/accounts"><BsPerson /> Accounts</Nav.Link>
              <Nav.Link as={Link} to="/search"><GoSearch /> Search</Nav.Link>
              <Nav.Link as={Link} to="/scan"><BiBarcodeReader /> Scan</Nav.Link>
              <Nav.Link as={Link} to="/social"><BsPeople /> Social</Nav.Link>
              <Nav.Link as={Link} to="/contact"><BsClock /> Contact</Nav.Link>
              <Nav.Link as={Link} to="/events"><BsCalendar /> Events</Nav.Link>
              <Nav.Link as={Link} to="/feedback"><HiOutlineClipboardList /> Feedback</Nav.Link> */}
          {/* </NavDropdown> */}
          <Drawer location={props.location} />
          </Navbar>

      </div>
    );
  }
  
  export default Navigation;