import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import './Events.css';
import Moment from 'react-moment'
import Navigation from './Navigation.js'
import {BsChevronCompactRight} from 'react-icons/bs';

function Events()
{
    
    const [events,setEvents] = useState([])

    useEffect(() => {   
        setEvents(JSON.parse(localStorage.getItem('events')))
        // fetch("https://www.icpl.org/new-api/events?start=2020-11-1&days=60&max=100")
        //     .then(response => response.json())
        //     .then(data =>{
        //             //display details
        //             console.log(data)
        //             setEvents(data.events)
        //     })
    },[])

    return(
        <div>
            <Navigation location='/events' />

            <div className="eventsMain">
                <h1>Upcoming Events</h1>
                    {
                        events.map((event) => (
                            <Link className="link" key={event.id} to={{
                                pathname: './event',
                                eventProps: {
                                    eventID: event.id
                                }
                            }} >                                                  
                                <Card className="eventsCard">
                                    <Card.Body className='eventCard'>
                                        <div>
                                            <div className="eventTitle">{event.title}</div>
                                            <div className="eventTimes">
                                                <Moment format="dddd, MMMM D, YYYY">{event.start}</Moment><br/>
                                                <Moment format="h:mm a">{event.start}</Moment> to
                                                <Moment format=" h:mm a">{event.end}</Moment>
                                            </div>
                                        </div>
                                        <BsChevronCompactRight className="eventsChevron"/>
                                    </Card.Body>
                                </Card>
                            </Link>
                        ))
                    }            
            </div>
        </div>
    )
}

export default Events