import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import './Accounts.css';
import { BsArrowRightShort, BsPlus } from 'react-icons/bs'
import Navigation from './Navigation.js'
import Moment from 'react-moment'

export default function Accounts()
{
    const [data,setData] = useState([])
    const [showAll,setShowAll] = useState(false)
    const [noAccounts,setNoAccounts] = useState(false)

    useEffect(() => {

        const patrons = [];
        for(let i=0; i<localStorage.length; i++){
            const key = localStorage.key(i);
            if (!isNaN(key)){
                patrons.push(JSON.parse(localStorage.getItem(key)));
            }
        }
        console.log("from Accounts.js")
        console.log(patrons);
        if(patrons.length > 1){
            setShowAll(true)
        }
        if ( patrons.length === 0 ) {
            setNoAccounts(true)
        }
        setData(patrons);
    },[])

    return (
        <div>
            <Navigation location='/accounts'/>
            <div className="accountsMain">
                <h1>Accounts</h1>
                <Link className="add-account" to="./add"><Button variant="primary" size="sm"><BsPlus />Add account</Button></Link>
                    {
                        data.map((patrons) => (
                        <Link className="link" key={patrons.patronID} to={{
                            pathname: './account',
                            accountProps: {
                                barcode: patrons.barcode,
                                // password: patrons.password,
                                name: patrons.name,
                                patronID: patrons.patronID,
                                hash: patrons.hash
                            }
                        }} >                    
                        <Card>
                            <Card.Body className="accountsBody">
                                <BsArrowRightShort className="accountsArrow"/>
                                <strong>{patrons.name}</strong><br />
                                {patrons.barcode}
                                {patrons.expiration_status == "expired" ? <div><span className="text-danger">Expired on <Moment format="MMM D, YYYY">{patrons.expiration}</Moment></span></div> : ""}
                                {patrons.expiration_status == "soon" ? <div><span className="text-danger">Expiring on <Moment format="MMM D, YYYY">{patrons.expiration}</Moment></span></div> : ""}
                                <div className="acct-summary">
                                {patrons.item_count !== "0" && <div>Borrowed: {patrons.item_count}{patrons.items_due_soon !== "0" || patrons.items_overdue !== "0" ? "; " : ""}
                                    {patrons.items_due_soon !== "0" ? <span className="text-warning">Due soon: {patrons.items_due_soon}{patrons.items_due_soon !== "0"&& patrons.items_overdue !== "0" ? "; " : ""}</span> : ""}
                                    {patrons.items_overdue !== "0" ? <span className="text-danger">Overdue: {patrons.items_overdue}</span> : ""}</div>}
                                {patrons.hold_count !== "0" && <div>Holds: {patrons.hold_count}{patrons.hold_ready !== "0" ? "; " : ""}
                                    {patrons.hold_ready !== "0" ? <span className="text-success">Ready for pickup: {patrons.hold_ready}</span> : ""}</div>}
                                {/* {patrons.fine_total !== "0.00" && <div> Fines: ${patrons.fine_total}</div>} */}
                                </div>
                            </Card.Body>
                        </Card>
                        </Link>
                        ))
                    }
                    {showAll && (
                    <Link className="link" key="all" to={{
                            pathname: './account',
                            accountProps: {
                                // barcode: patrons.barcode,
                                // password: patrons.password,
                                // name: patrons.name,
                                patronID: 'all'
                            }
                        }} >                    
                        <Card>
                            <Card.Body style={{fontStyle:"italic"}} className="accountsBody">
                            <BsArrowRightShort className="accountsArrow"/>
                                All Accounts
                            </Card.Body>
                        </Card>
                        </Link>
                    )}
                    {noAccounts && (<p><em>You haven't added any accounts yet.</em></p>)}
            </div>
        </div>
    )
}