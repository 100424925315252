import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import "./Add.css"
import Accounts from './Accounts'
import { Route, BrowserRouter as Switch } from 'react-router-dom'
import Navigation from './Navigation.js'
import ReactGA from 'react-ga4'

export default function Add() {

    const [name, setName] = useState("");
    const [barcode, setBarcode] = useState("")
    const [pin, setPin] = useState("")
    const [redirect, setRedirect ] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    
    function AddModal(props) {
        return (
          <Modal
            {...props}

            // aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>Login failed</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>The library card and/or password are incorrect. Please try again.</p>
              <p>Need assistance? Call us at 319-356-5200 or <a href='https://www.icpl.org/services/ask'>email or chat</a> with us online.</p>
            </Modal.Body>

          </Modal>
        );
      }
      
      function accountAddEvent(linkText){
        ReactGA.event('account_add_click', {
          link_text: linkText,
        });
      };
      function loginEvent(loginMessage, appLocation){
        ReactGA.event('account_login_status', {
          message: loginMessage,
          app_location: appLocation,
        });
      };

      const handleSubmit = (event) => {
        accountAddEvent('Submit')

        event.preventDefault();
        const patronData = '[{"name":"'+[name]+'","barcode": "'+[barcode]+'","password": "'+[pin]+'"}]'
        // console.log(patronData);
        fetch("https://www.icpl.org/catalog/app/accountReact.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: (patronData),
        })
            .then(response => response.json())
            .then(data =>{
                if (data.authenticated === 'true'){
                    loginEvent('Login success', '/addAccount')
                    localStorage.setItem(data.patronID, JSON.stringify(data));
                    console.log(data)
                    setRedirect(true)
                }
                else{
                    //add modal saying failed
                    loginEvent('Login failed', '/addAccount')
                    setModalShow(true)
                    console.log(data)
                }
            })

            // //send device id/patron id combo to mysql
            // const msgToken = localStorage.getItem('msgToken');
            // const tokenData = '[{"patron_num":"'+[data.patronID]+'","token": "'+[msgToken]+'","barcode": "'+[barcode]+'","action": "add"}]'
            // console.log(tokenData)
            // fetch("https://www.icpl.org/catalog/app/msgTokens.php", {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: (tokenData),
            // })
            //     .then(response => response.json())
            //     .then(data =>{
            //         console.log("In second fetch")
            //         // if (data.authenticated === 'true'){
            //         //     localStorage.setItem(data.patronID, JSON.stringify(data));
            //         //     console.log(data)
            //         // }
            //     })

            // console.log("After second fetch")            
    }

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const patronData = '[{"name":"'+[name]+'","barcode": "'+[barcode]+'","password": "'+[pin]+'"}]'
    //     console.log(patronData);
    //     fetch("https://www.icpl.org/catalog/app/accountReact.php", {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: (patronData),
    //     })
    //         .then(response => response.json())
    //         .then(data =>{
    //             if (data.authenticated === 'true'){
    //                 localStorage.setItem(data.patronID, JSON.stringify(data));
                    
    //                 // //send device id/patron id combo to mysql
    //                 // const msgToken = localStorage.getItem('msgToken');
    //                 // const tokenData = '[{"patron_num":"'+[data.patronID]+'","token": "'+[msgToken]+'","barcode": "'+[barcode]+'","action": "add"}]'
    //                 // console.log(tokenData)
    //                 // fetch("https://www.icpl.org/catalog/app/msgTokens.php", {
    //                 //     method: 'POST',
    //                 //     headers: {
    //                 //         'Content-Type': 'application/json',
    //                 //     },
    //                 //     body: (tokenData),
    //                 // })
    //                 //     .then(response => response.json())
    //                 //     .then(data =>{
    //                 //         console.log("In second fetch")
    //                 //         if (data.authenticated === 'true'){
    //                 //             localStorage.setItem(data.patronID, JSON.stringify(data));
    //                 //             console.log(data)
    //                 //         }
    //                 //     })

    //                 // console.log("After second fetch")
    //                 console.log(data)
    //                 setRedirect(true)
    //             }
    //             else{
    //                 //add modal saying failed
    //                 setModalShow(true)
    //                 console.log(data)
    //             }
    //         })
    // }

    if(redirect === true){
        return <Redirect to="/accounts" />
    }
    else{
        return (
            <div>
                <Navigation location='/add'/>
                <div className="accountForm">
                    <h1>Add an account</h1>
                    <AddModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    />
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                                <Form.Label>Name this account</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={name} 
                                name="name" 
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBarcode">
                            <Form.Label>Library Card Number</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={barcode} 
                                placeholder="2197700..." 
                                name="barcode" 
                                onChange={e => setBarcode(e.target.value)}
                            />
                        </Form.Group>                
                        <Form.Group controlId="formPin">
                            <Form.Label>Password/PIN</Form.Label>
                            <Form.Control 
                                type="password" 
                                value={pin} 
                                name="pin" 
                                onChange={e => setPin(e.target.value)}
                                />
                                <Form.Text className="text-muted">
                                    <a href="https://www.icpl.org/pin-reset" onClick={()=>{accountAddEvent('Forgot your password?')}}>Forgot your password?</a>
                                </Form.Text>
                        </Form.Group>
                        <Button variant="success" type="submit">Submit</Button>
                    </Form>
                        <div className="text-muted center"><a href="/accounts" onClick={()=>{accountAddEvent('Return to accounts page')}}>Return to accounts page</a></div>

                    <Switch>
                        <Route path="/accounts" component={Accounts}></Route>
                    </Switch>
                </div>
            </div>
        )
    }

}