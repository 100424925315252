import React from 'react'

function Scan()
{
    return(
        <div>
            <h1>Scan ISBN</h1>
        </div>
    )
}

export default Scan