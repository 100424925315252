import React, {useState, useEffect} from 'react';
// import logo from './logo.svg';
import './App.css';
// import Navigation from './components/Navigation.js'

import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import {Route, Switch} from 'react-router-dom'

//import pages for routing
import Home from './components/Home'
import Accounts from './components/Accounts'
import Search from './components/Search'
import Scan from './components/Scan'
import Social from './components/Social'
import Contact from './components/Contact'
import Events from './components/Events'
import Posts from './components/Posts'
import Feedback from './components/Feedback'
import Add from './components/Add'
import Account from './components/Account'
import Barcode from './components/Barcode'
import Blogpost from './components/Blogpost'
import Event from './components/Event'
import EditAccount from './components/EditAccount'
import Settings from './components/Settings'
// import firebase from './firebase'
import ReactGA from 'react-ga4'
import Modal from 'react-bootstrap/Modal'

const ClearCacheComponent = withClearCache(MainApp);
const today = new Date()

function App(){
  return <ClearCacheComponent />;
}

function initializeReactGA() {
  ReactGA.initialize('G-BF3JVCSJ1X',{
    debug: true,
    titleCase: false
  })
}
initializeReactGA()

function MainApp(props) {
  const [RefreshModalShow, setRefreshModalShow] = useState(false);
  const [accountError, setAccountError] = useState("");

  // const [data,setData] = useState([])
  useEffect(() => {
      // const msg=firebase.messaging();
      // Notification.requestPermission().then(()=>{
      //   console.log(msg);
      //   if (msg.vapidKey !== null){
      //     console.log("Notifications allowed")
      //     console.log(msg.getToken());
      //     return msg.getToken();
      //   }
      //   else{
      //     console.log("Notifications blocked")
      //   }
      // }).then((data)=>{
      //   localStorage.setItem('msgToken',data)
      //   console.warn("token",data)
      // })

      const patrons = [];
      for(let i=0; i<localStorage.length; i++){
          let key = localStorage.key(i)
          if (!isNaN(key)){
            let patron = JSON.parse(localStorage.getItem(key))
            //get details from accountReact.php
            let patronData = '';
            if (typeof patron.password !== 'undefined'){
              console.log(key);
              console.log('Password is set');
              patronData = '[{"name":"'+[patron.name]+'","barcode": "'+[patron.barcode]+'","password": "'+[patron.password]+'"}]'
            }
            else if (typeof patron.hash !== 'undefined'){
              if (key.length > 0){
                console.log(key);
                console.log('Hash is set');
                patronData = '[{"name":"'+[patron.name]+'","barcode": "'+[patron.barcode]+'","hash": "'+[patron.hash]+'"}]'
              }
              else{
                //removes account that have an empty key
                localStorage.removeItem(key);
              }
            }
            
            // const patronData = '[{"name":"'+[patron.name]+'","barcode": "'+[patron.barcode]+'","password": "'+[patron.password]+'"}]'
            // console.log(patronData);
            fetch("https://www.icpl.org/catalog/app/accountReact.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: (patronData),
            })
                .then(response => response.json())
                .then(data =>{
                    if (data.authenticated === 'true'){
                        localStorage.setItem(data.patronID, JSON.stringify(data));
                        // console.log(data)
                        // setRedirect(true)
                    }
                    else{
                      //show modal here or wait until accounts page?

                        //add modal saying failed
                        setAccountError(patron.barcode)
                        setRefreshModalShow(true)
                        console.log(data)
                    }
                })
            patrons.push(patron)
          }
      }
      // console.log("from App.js")
      // console.log(patrons);
      // setData(patrons);
      const eventUrl = 'https://www.icpl.org/new-api/events?start='+today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+'&days=60&max=100'
      fetch(eventUrl)
        .then(response => response.json())
        .then(data =>{
          //display details
          // console.log(data.events)
          localStorage.setItem('events', JSON.stringify(data.events));
      })

      //get blogposts after events load
      fetch("https://www.icpl.org/new-api/news?per_page=5")
      .then(response => response.json())
      .then(data =>{
        //display details
        // console.log(data)
        localStorage.setItem('posts', JSON.stringify(data));
      })
  },[])

  function RefreshModal(props) {
    return (
      <Modal
        {...props}

        // aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header closeButton>
              <Modal.Title>Account update error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Unable to update the account for <strong>{accountError}</strong> because the Library card and/or password are incorrect</p>
          <p>To fix this, visit the account page and tap the Edit button to update.</p>
          <p>Need assistance? Call us at 319-356-5200 or <a href='https://www.icpl.org/services/ask'>email or chat</a> with us online.</p>
        </Modal.Body>

      </Modal>
    );
  }

  return (
    <div className="App">
      <RefreshModal
      show={RefreshModalShow}
      onHide={() => setRefreshModalShow(false)}
      />

      <Switch>
        <Route path="/accounts" component={Accounts}></Route>
        <Route path="/search" component={Search}></Route>
        <Route path="/scan" component={Scan}></Route>
        <Route path="/social" component={Social}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/events" component={Events}></Route>
        <Route path="/posts" component={Posts}></Route>
        <Route path="/feedback" component={Feedback}></Route>
        <Route path="/add" component={Add}></Route>
        <Route path="/account" component={Account}></Route>
        <Route path="/barcode" component={Barcode}></Route>
        <Route path="/blogpost" component={Blogpost}></Route>
        <Route path="/event" component={Event}></Route>
        <Route path="/editAccount" component={EditAccount}></Route>
        <Route path="/settings" component={Settings}></Route>
        <Route path="/" component={Home}></Route>
      </Switch>
    </div>
  );
}

export default App;
