import React from 'react'

function Posts()
{
    return(
        <div>
            <h1>Posts List</h1>
        </div>
    )
}

export default Posts