import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import './Drawer.css';

//import react-icons
import {VscHome} from 'react-icons/vsc';
import {FaRegAddressCard} from 'react-icons/fa';
import {GoSearch} from 'react-icons/go';
import {IoMdChatboxes} from 'react-icons/io';
import {HiOutlineClipboardList} from 'react-icons/hi'
import {BsCalendar} from 'react-icons/bs';
import {BsGear} from 'react-icons/bs';
import {HiMenu} from 'react-icons/hi';
import ReactGA from 'react-ga4'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer(props) {
  function headerEvent(linkText, appLocation){
    ReactGA.event('header_click', {
      link_text: linkText,
      app_location: appLocation,
    });
  };
  function menuEvent(linkText, appLocation){
    ReactGA.event('menu_click', {
      link_text: linkText,
      app_location: appLocation,
    });
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (open) {
      headerEvent('Open menu', props.location)
    } else {
      headerEvent('Close menu', props.location)
    }
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link href='/' onClick={()=>{menuEvent('Home', props.location)}}>
          <ListItem button key='Home'>
            <ListItemIcon><VscHome /></ListItemIcon>
            <ListItemText primary='Home' />
          </ListItem>
        </Link>     
        <Link href='/accounts' onClick={()=>{menuEvent('Accounts', props.location)}}>
            <ListItem button key='Accounts'>
                <ListItemIcon><FaRegAddressCard /></ListItemIcon>
                <ListItemText primary='Accounts' />
            </ListItem>
        </Link>
        <Link href='/search' onClick={()=>{menuEvent('Search', props.location)}}>
          <ListItem button key='Search'>
            <ListItemIcon><GoSearch /></ListItemIcon>
            <ListItemText primary='Search' />
          </ListItem>
        </Link>
        <Link href='/events' onClick={()=>{menuEvent('Events', props.location)}}>
          <ListItem button key='Events'>
            <ListItemIcon><BsCalendar /></ListItemIcon>
            <ListItemText primary='Events' />
          </ListItem>
        </Link>
        <Link href='/contact' onClick={()=>{menuEvent('Contact', props.location)}}>
          <ListItem button key='Contact'>
            <ListItemIcon><IoMdChatboxes /></ListItemIcon>
            <ListItemText primary='Contact' />
          </ListItem>
        </Link>
        <Link href='/feedback' onClick={()=>{menuEvent('Feedback', props.location)}}>
          <ListItem button key='Feedback'>
            <ListItemIcon><HiOutlineClipboardList /></ListItemIcon>
            <ListItemText primary='Feedback' />
          </ListItem>
        </Link>
        <Link href='/settings' onClick={()=>{menuEvent('Settings', props.location)}}>
          <ListItem button key='Settings'>
            <ListItemIcon><BsGear /></ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
        <React.Fragment key='right'>
          <Button id='menuBtn' onClick={toggleDrawer('right', true)}><HiMenu size={28}/></Button>
          <SwipeableDrawer
            anchor='right'
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
          >
            {list('right')}
          </SwipeableDrawer>
        </React.Fragment>
    </div>
  );
}
