import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import "./Feedback.css"
import {IoMdClipboard} from 'react-icons/io';
import Navigation from './Navigation.js'
import ReactGA from 'react-ga4'

export default function Feedback() {
    
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("")
    const [redirect, setRedirect ] = useState(false)
    const [feedbackModalShow, setFeedbackModalShow] = useState(false)

    function Event(linkText){
        ReactGA.event('feedback_click', {
          link_text: linkText,
        });
    };

    const handleSubmit = (event) => {
        Event('Submit')

        event.preventDefault()
        const feedbackData = '{"email":"'+[email]+'","feedback": "'+[feedback.replace(/"/g, '\\"')]+'"}'

        fetch("https://apps.icpl.org/catalog/app/feedbackReact.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: (feedbackData),
        })
            .then(response => response.json())
            .then(data =>{
                if (data.feedback === 'true'){
                //show thank you modal
                //on close return home?
                console.log(data)
                setFeedbackModalShow(true)
                }
            })
    }
    
    function FeedbackModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Thank You!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Thanks for the feedback. It will help us keep improving the app.</p>
                <p>If you asked a question and provided an email we will respond as soon as we can.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>

        );
      }
    if(redirect === true){
        return <Redirect to="/" />
    }
    else{
        return(
            <div>
                <Navigation location='/feedback'/>
                <div className="feedbackMain">
                    <h1>Send us feedback!</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={email} 
                                name="email" 
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Please leave your email if you would like a response
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formFeedback">
                            <Form.Label>Tell us about your experience with the app</Form.Label>
                            <Form.Control 
                                as="textarea"
                                rows={5} 
                                value={feedback} 
                                name="feedback" 
                                onChange={e => setFeedback(e.target.value)}
                            />
                        </Form.Group>                
                        <Button variant="primary" type="submit"><IoMdClipboard /> <span className="v-middle">Send Feedback</span></Button>
                    </Form>

                    <FeedbackModal
                        show={feedbackModalShow}
                        onHide={() => setRedirect(true) }
                    />
                </div>
            </div>
        )
    }

}