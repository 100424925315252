import React, {useState, useEffect, useRef} from 'react'
import Navigation from './Navigation.js'
import './EditAccount.css';
import ReactGA from 'react-ga4'
import Button from 'react-bootstrap/Button'
import {Link, Redirect} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

export default function EditAccount(props)
{
    console.log(props)
    //get entry from localstorage
    //using ref or this is null after deleting from local storage
    const refAccount = useRef(JSON.parse(localStorage.getItem(props.location.editProps.patronID)))
    const [currPatron] = useState(refAccount.current.patronID)
    // const currPatron = JSON.parse(localStorage.getItem('currPatron'))
    // const patronDetails = JSON.parse(localStorage.getItem(currPatron))

    const [name,setName] = useState(refAccount.current.name)
    const [barcode,setBarcode] = useState(refAccount.current.barcode)
    const [pin,setPin] = useState("")

    const [updateAccount,setUpdateAccount] = useState(false)
    const [deleteAccount,setDeleteAccount] = useState(false)
    const [editModalShow, setEditModalShow] = useState(false);

    function clickEvent(linkText){
        ReactGA.event('account_edit_click', {
          link_text: linkText,
        });
    };
    function statusEvent(linkText){
        ReactGA.event('account_edit_click', {
          link_text: linkText,
          app_location: '/editAccount',
        });
    };

    //update button
    const handleSubmit = (event) => {
        event.preventDefault();
        // setName(patronDetails.name);
        const patronData = '[{"name":"'+[name]+'","barcode": "'+[barcode]+'","password": "'+[pin]+'"}]'
        console.log(patronData);
        fetch("https://www.icpl.org/catalog/app/accountReact.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: (patronData),
        })
            .then(response => response.json())
            .then(data =>{
                if (data.authenticated === 'true') {
                    statusEvent('Login success')
                    localStorage.setItem(data.patronID, JSON.stringify(data));
                    console.log(data)
                    setUpdateAccount(true)
                }
                else {
                    statusEvent('Login fail')
                    console.log(editModalShow)
                    setEditModalShow(true)
                    console.log(editModalShow)
                    console.log(data)
                }
            })
    }
    
    //delete button
    const handleDelete = (event) => {
        event.preventDefault()
        console.log("DELETED")
        setDeleteAccount(true)
    }
    
    function EditModal(props) {
        return (
          <Modal
            {...props}

            // aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>Login failed</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>The library card and/or password are incorrect. Please try again.</p>
              <p>Need assistance? Call us at 319-356-5200 or <a href='https://www.icpl.org/services/ask'>email or chat</a> with us online.</p>
            </Modal.Body>

          </Modal>
        );
    }

    if(deleteAccount === true){
        localStorage.removeItem(currPatron)
        return <Redirect to="/accounts" />
    }
    else if (updateAccount === true){
        return <Redirect to="/accounts" />
    }
    else{
        return(
            <div>
                <Navigation location='/editAccount' />
                
                <EditModal
                    show={editModalShow}
                    onHide={() => setEditModalShow(false)}
                />
                <div className="accountForm">
                    <h1>Edit account</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            <Form.Label>Name this account</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={name} 
                                name="patronname" 
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBarcode">
                            <Form.Label>Library Card Number</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={barcode} 
                                placeholder="2197700..." 
                                name="barcode" 
                                onChange={e => setBarcode(e.target.value)}
                            />
                        </Form.Group>                
                        <Form.Group controlId="formPin">
                            <Form.Label>Password/PIN</Form.Label>
                            <Form.Control 
                                type="password" 
                                value={pin} 
                                name="pin" 
                                onChange={e => setPin(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                    <a href="https://www.icpl.org/pin-reset" onClick={()=>{clickEvent('Forgot your password')}}>Forgot your password?</a>
                                </Form.Text>
                        </Form.Group>
                        <Button variant="success" type="submit" onClick={()=>{clickEvent('Submit')}}>Update</Button>
                    </Form>
                    <Link class="cancel-edit" to="./account" onClick={()=>{clickEvent('Cancel')}}><Button variant="default">Cancel</Button></Link>
                    {/* make a separate form for deleting*/}
                    <Form onSubmit={handleDelete}>
                        <Button  className="delete-account-btn link-danger" variant="link" type="submit"  onClick={()=>{
                            clickEvent('Delete this account');
                        }}>Delete this account</Button>
                    </Form>
                </div>
            </div>
        )
    }

}