import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Moment from 'react-moment'
import CardActions from '@material-ui/core/CardActions';
import { List, ListItem, ListItemIcon, Divider, ListItemText } from '@material-ui/core'
import './Contact.css'
import '../App.css'
import Iframe from 'react-iframe'
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaRegClock } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import { IoIosChatbubbles } from 'react-icons/io'
import { BsChevronCompactRight } from 'react-icons/bs'
import Navigation from './Navigation.js'
import ReactGA from 'react-ga4'

function Contact() {

    const [holidays, setHolidays] = useState([])

    useEffect(() => {
        if ( localStorage.getItem('holidays') !== null ) {
            setHolidays(JSON.parse(localStorage.getItem('holidays')))
        }
        else {
            const today = new Date()
            const year = today.getFullYear()
            const month = today.getMonth()+1
            const day = today.getDate()
            fetch("https://www.icpl.org/new-api/anomaly?start="+year+"-"+month+"-"+day+"&days=31")
                .then(response => response.json())
                .then(data =>{
                    //display details
                    console.log(data)
                    setHolidays(data)
                    localStorage.setItem('holidays', JSON.stringify(data))
                })
        }
    },[])

    function contactEvent(linkText){
        ReactGA.event('contact_click', {
          link_text: linkText,
        });
    };
    function socialEvent(linkText){
        ReactGA.event('social_click', {
          link_text: linkText,
        });
    };

    function HolidayModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                className="holiday-modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h3">Upcoming holiday hours</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <dl>
                    { (holidays.filter(holiday => holiday.downtown !== "1").length > 0)
                    ? holidays.filter(holiday => holiday.downtown !== "1").map((holiday) => (
                            <div key={holiday.id}>
                                <dt>
                                    <span className="holidayTitle">{holiday.title}</span>
                                </dt>
                                <dd>
                                <span className="holidayDate">
                                <Moment format="ddd, MMM D, YYYY">{holiday.start}</Moment>
                                </span><br/>
                                    <span className="holidayHours">
                                {holiday.hours}
                                </span>
                                </dd>
                            </div>
                        ))
                    : "There are no upcoming holidays." }
                    </dl>
                </Modal.Body>
            </Modal>
        );
    }

    function HolidayTrigger() {
        const [modalShow, setModalShow] = React.useState(false);

        return (
            <>
                <Button size="small" variant="default" onClick={() => {setModalShow(true);contactEvent('Upcoming holiday hours');}}>Upcoming holiday hours <BsChevronCompactRight/></Button>

                <HolidayModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </>
        );
    }

    // constants for regular hours
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const defaultHours = ["12 - 5pm", "10am - 9pm", "10am - 9pm", "10am - 9pm", "10am - 9pm", "10am - 8pm", "10am - 6pm"]
    const defaultOpen = [12, 10, 10, 10, 10, 10, 10]
    const defaultClose = [17, 21, 21, 21, 21, 20, 18]
    const plainHours = {10:'10am', 12:'12pm', 16:'4pm', 17:'5pm', 18:'6pm', 20:'8pm', 21:'9pm'}


    function HoursStatus() {
        //get today's date and hour
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()+1
        const day = today.getDate()
        const todayStr = year+"-"+(month<10?'0'+month:month)+"-"+(day<10?'0'+day:day)
        const weekday = today.getDay()
        const hour = today.getHours()

        // assumption that hours-affecting holidays are one day only
        let holidaysToday = holidays.filter(holiday => holiday.start.substring(0,10) === todayStr && holiday.start.substring(0,10) === todayStr && holiday.downtown_key !== '1')

        let status, statusStyle, statusTag, hoursMain, todayOpen, todayClose

        //check if it's a holiday
        //  return open and close times and hoursMain with 'day: hours'/'holiday: hours'
        if ( holidaysToday.length > 0 ) {
            hoursMain = holidaysToday[0].title
            todayOpen = holidaysToday[0].hoursStart
            todayClose = holidaysToday[0].hoursEnd
            if ( holidaysToday[0].downtown_key === '0' ) {
                hoursMain += ': ' + holidaysToday[0].hours
            } else{
                hoursMain += ': ' + holidaysToday[0].hours.substring(5)
            }
        } else {
            hoursMain = days[weekday] + ': ' + defaultHours[weekday]
            todayOpen = defaultOpen[weekday]
            todayClose = defaultClose[weekday]
        }

        //check now status - open, closed, closing soon
        //if closed and past today's open time find next open
        //  check subsequent days for holidays and regular hours until one with open hours is found
        //  return day and time of next open
        if ( hour >= todayOpen && hour < todayClose && (hour+1) === todayClose ) {
            status = 'Closing soon'
            statusStyle = 'hoursWarning'
            statusTag = ' - Closes at '+plainHours[todayClose]
        } else if ( hour >= todayOpen && hour < todayClose) {
            status = 'Open now'
            statusStyle = 'hoursSuccess'
            statusTag = ' - Closes at '+plainHours[todayClose]
        } else if ( hour < todayOpen ) {
            status = 'Closed now'
            statusStyle = 'hoursDanger'
            statusTag = ' - Opens ' + plainHours[todayOpen] + ' today'
        } else {
            status = 'Closed now'
            statusStyle = 'hoursDanger'
            let nextDay = new Date()
            let year, month, day, weekday, nextStr
            let nextOpen = 0
            while (nextOpen === 0) {
                nextDay.setDate(nextDay.getDate()+1)
                year = nextDay.getFullYear()
                month = nextDay.getMonth()+1
                day = nextDay.getDate()
                nextStr = year+"-"+(month<10?'0'+month:month)+"-"+(day<10?'0'+day:day)
                weekday = nextDay.getDay()
                // assumption that hours-affecting holidays are one day only
                let holidaysNext = holidays.filter(holiday => holiday.start.substring(0,10) === nextStr && holiday.start.substring(0,10) === nextStr && holiday.downtown_key !== '1')
                if ( holidaysNext.length > 0 ) {
                    nextOpen = holidaysNext[0].hoursStart
                } else {
                    nextOpen = defaultOpen[weekday]
                }
            }
            statusTag = ' - Opens '+plainHours[todayOpen]+' '+days[weekday]
        }

        return (
            <Card.Body className={ statusStyle }>
                <h3>{ hoursMain }</h3>
                <span className="statusText">{ status }</span> { statusTag }
            </Card.Body>
        )
    }

    return (
        <div>
            <Navigation location='/contact' />
            <div className="contactMain">
                <h1>Hours & Contact</h1>
                <Card>
                    <Card.Header as="h2"><FaRegClock /> Today's Hours</Card.Header>
                    <HoursStatus/>
                </Card>

                <Card>
                    <Card.Header as="h2">Hours</Card.Header>
                    <Card.Body>
                        <table className="hoursTable">
                            <tbody>
                            {
                                days.map((day, index) => (
                                    <tr key={day}>
                                        <td>{day}</td>
                                        <td>{defaultHours[index]}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </Card.Body>
                    <CardActions>
                        <HolidayTrigger />
                    </CardActions>
                </Card>

                <Card>
                    <Card.Header as="h2">Contact</Card.Header>
                        <List>
                            <ListItem button component="a" href="tel:3193565200" onClick={()=>{contactEvent('Call')}}>
                                <ListItemIcon>
                                    <FaPhoneAlt />
                                </ListItemIcon>
                                <ListItemText primary="Call" secondary="319-356-5200"/>
                            </ListItem>
                            <Divider />
                            <ListItem button component="a" href="mailto:refmail@icpl.org" onClick={()=>{contactEvent('Email')}}>
                                <ListItemIcon>
                                    <IoIosMail />
                                </ListItemIcon>
                                <ListItemText primary="Email" />
                            </ListItem>
                            <Divider />
                            <ListItem button component="a" href="https://www.icpl.org/services/ask#chat" onClick={()=>{contactEvent('Chat')}}>
                                <ListItemIcon>
                                    <IoIosChatbubbles />
                                </ListItemIcon>
                                <ListItemText primary="Chat" />
                            </ListItem>
                        </List>
                </Card>

                <Card>
                    <Card.Header as="h2">Location</Card.Header>
                    <Card.Body style={{paddingBottom:0}}>
                        <p>Iowa City Public Library<br />
                            123 South Linn Street<br />
                            Iowa City, IA 52240</p>
                    </Card.Body>
                    <CardActions>
                        <Button size="small" variant="default" component="a" href="https://goo.gl/maps/GtCW1uXzP3s3q2GN8" onClick={()=>{contactEvent('Get directions')}}>Get directions <BsChevronCompactRight/></Button>
                    </CardActions>
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2980.848820567605!2d-91.53406898430534!3d41.659008287074684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87e441efbdaac61b%3A0x91a4c3ad7a0132f7!2sIowa%20City%20Public%20Library!5e0!3m2!1sen!2sus!4v1606762899497!5m2!1sen!2sus" width="100%" height="300" frameBorder="0" allowFullScreen="" />
                </Card>

                <Card>
                    <Card.Header as="h2">Social media</Card.Header>
                    <List>       
                        <ListItem button component="a" href="https://www.icpl.org/facebook" onClick={()=>{socialEvent('Facebook')}}>
                            <ListItemIcon>
                                <FaFacebook />
                            </ListItemIcon>
                            <ListItemText primary="Facebook" />
                        </ListItem>
                        <Divider />
                        <ListItem button component="a" href="https://www.icpl.org/twitter" onClick={()=>{socialEvent('Twitter')}}>
                            <ListItemIcon>
                                <FaTwitter />
                            </ListItemIcon>
                            <ListItemText primary="Twitter" />
                        </ListItem>
                        <Divider />
                        <ListItem button component="a" href="https://www.icpl.org/instagram" onClick={()=>{socialEvent('Instagram')}}>
                            <ListItemIcon>
                                <FaInstagram />
                            </ListItemIcon>
                            <ListItemText primary="Instagram" />
                        </ListItem>
                        <Divider />
                        <ListItem button component="a" href="https://www.icpl.org/youtube" onClick={()=>{socialEvent('YouTube')}}>
                            <ListItemIcon>
                                <FaYoutube />
                            </ListItemIcon>
                            <ListItemText primary="YouTube" />
                        </ListItem>
                    </List>
                </Card>

            </div>
        </div>
    )
}

export default Contact