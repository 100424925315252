import React, {useState, useEffect} from 'react'
import './Alert.css';

export default function Alert() {
    const [level,setLevel] = useState('')
    const [message,setMessage] = useState('')

    useEffect(()=>{
        //get siteAlert
        fetch("https://www.icpl.org/new-api/site_alert?origin=app")
        .then(response => response.json())
        .then(data =>{
            setLevel(data.level)
            setMessage(data.message)
            console.log("Site alert: "+data.message)
        })
    },[])
    
    if (level !== "0"){
        return(
            <div>
                <div className={'app-banner '+level} dangerouslySetInnerHTML={{__html: message}}></div>
            </div>
        )
    }
    else{
        return(
            <div></div>
        )
    }
}