import React from 'react';
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Home.css';
import Blogposts from './Blogposts.js'
// import logo from '../images/icpl_logo.png'
import Navigation from './Navigation.js'
import Alert from './Alert.js'
import {useLocation} from 'react-router-dom'

//import react-icons
import { GoSearch } from 'react-icons/go';
import { FaRegAddressCard } from 'react-icons/fa'
import { IoMdChatboxes } from 'react-icons/io'
import { BsCalendar } from 'react-icons/bs'
// import { RiBookOpenLine } from 'react-icons/ri';
import ReactGA from 'react-ga4'

function Home() {

    function Event(linkText){
        ReactGA.event('home_click', {
          link_text: linkText,
        });
    };

    const location = useLocation()
    return (
        <div>
            <Navigation location={location.pathname}/>
            <Alert />
            <div className="homeMain">
                <Container className="center">
                    <Row>
                        <Col xs={6}>
                            <Card bg='danger' className="homeCard">
                                <Link to="/accounts" onClick={()=>{Event('Accounts')}}>
                                <Card.Body>
                                        <Card.Title><FaRegAddressCard /><br />Accounts</Card.Title>
                                </Card.Body>
                                </Link>
                                </Card>
                        </Col>
                        <Col xs={6}>
                            <Card bg='primary' className="homeCard">
                            <Link to="/search" onClick={()=>{Event('Search')}}>
                                <Card.Body>
                                <Card.Title><GoSearch /><br />Search</Card.Title>
                                </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xs={6}>
                            <Card bg='success' className="homeCard">
                                <Link to="/events">
                                    <Card.Body>
                                        <Card.Title><BsFillCalendarFill /><br />Events</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={6}>
                            <Card bg='danger' className="homeCard">
                            <Link to="/social">
                                <Card.Body>
                                <Card.Title><BsPeople /><br />Social</Card.Title>
                                </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>                 */}
                    <Row>
                        <Col xs={6}>
                            <Card bg='success' className="homeCard">
                            <Link to="/events" onClick={()=>{Event('Events')}}>
                                <Card.Body>
                                        <Card.Title><BsCalendar /><br />Events</Card.Title>
                                </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={6}>
                            <Card bg='warning' className="homeCard">
                                <Link to="/contact" onClick={()=>{Event('Contact')}}>
                                    <Card.Body>
                                        <Card.Title><IoMdChatboxes /><br />Contact</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container> 
                    <br />
                <Blogposts />              
            </div>
        </div>
    );
}

export default Home;