import React, {useState} from 'react'
import { useBarcode } from '@createnextapp/react-barcode';
import './Barcode.css';

export default function Barcode(props)
{
  
  const [barcode] = useState(String(props.location.barcodeProps.barcode));
  const { inputRef } = useBarcode({
    value: [barcode],
    options: {
      background: "#fff",
      format: "codabar"
      //https://reactjsexample.com/react-component-to-generate-barcode/
    }
  });
  
  return (
    <div className='barcodeDiv'>
      <img ref={inputRef} alt={barcode}/>
    </div>
  )
}
