import React, {useState, useEffect} from 'react'
import Navigation from './Navigation.js'
import Moment from 'react-moment'
import './Blogpost.css';

export default function Blogpost(props)
{
    let tempPostID = '';
    if (props.location.blogpostProps !== undefined){
        tempPostID = props.location.blogpostProps.postID
        localStorage.setItem('currPost',tempPostID)
    }
    else{
        tempPostID = localStorage.getItem('currPost')
    }
    const [postid] = useState(tempPostID);

    // const [postid] = useState(props.location.blogpostProps.postID);
    const [title, setTitle] = useState([])
    const [content, setContent] = useState([])
    const [user, setUser] = useState([])
    const [postDate, setPostDate] = useState([])

    useEffect(() => {
 
        //gets post details from storage
        const posts = JSON.parse(localStorage.getItem('posts'))
        for (var i=0 ; i < posts.length ; i++)
        {
            if (posts[i]['id'] === postid) {
                const storedPost = posts[i]
                console.log(storedPost);
                // setPost(storedPost)
                setTitle(storedPost.title)
                setContent(storedPost.content)
                setUser(storedPost.user)
                setPostDate(storedPost.published_at)
            }
        }

        // fetch("https://www.icpl.org/new-api/news/"+postid)
        //     .then(response => response.json())
        //     .then(data =>{
        //             setPost(data)
        //             setTitle(data.title)
        //             setContent(data.content)
        //     })
    },[])

    return(
        <div>
            <Navigation location='/blogpost' />
            
            <div className='blogpostMain'>
                <h1>{title.rendered}</h1>
                <p className="blog-byline">Posted <Moment format="MMM D, YYYY">{postDate}</Moment> by {user}</p>
                <div className="blogpostBody" dangerouslySetInnerHTML={{__html: content.rendered}}></div>
            </div>
        </div>
    )
}