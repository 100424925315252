import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import './Account.css';
import {Link} from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
// import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
// import Alert from 'react-bootstrap/Alert'
// import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { IoIosCall } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { IoIosChatbubbles } from 'react-icons/io'
import { FaPencilAlt } from 'react-icons/fa';
import { FaBarcode } from 'react-icons/fa';
import { useBarcode } from '@createnextapp/react-barcode';
import { IoIosSnow } from 'react-icons/io'
import { FaBell } from 'react-icons/fa'
import Moment from 'react-moment'
import "./Add.css"
// import spinner from '../images/logoGrowBlueFast.svg'
import Navigation from './Navigation.js'
import ReactGA from 'react-ga4'

export default function Account(props)
{
    function actionEvent(linkText, appLocation) {
        ReactGA.event('account_action_click', {
            link_text: linkText,
            app_location: appLocation,
        });
    };
    function actionStatusEvent(message, appLocation) {
        ReactGA.event('account_action_status', {
            message: message,
            app_location: appLocation,
        });
    };
    function contactEvent(linkText, appLocation) {
        ReactGA.event('account_contact_click', {
            link_text: linkText,
            app_location: appLocation,
        });
    };
    function accountTypeEvent(category) {
        ReactGA.event('account_page_type', {
            category: category,
        });
    };

    let tempPatronID = '';
    if (props.location.accountProps !== undefined){
        tempPatronID = props.location.accountProps.patronID
        localStorage.setItem('currPatron',tempPatronID)
    }
    else{
        tempPatronID = localStorage.getItem('currPatron')
    }
    const [patronID] = useState(tempPatronID);

    const [name,setName] = useState("")
    const [barcode,setBarcode] = useState("")
    // const [pin,setPin] = useState("")
    // const [patronID] = useState(props.location.accountProps.patronID)
    const [patron, setPatron] = useState(JSON.parse(localStorage.getItem(tempPatronID)))
    console.log(patron)
    const [items, setItems] = useState([])
    const [itemCount, setItemCount] = useState(0)
    const [fines, setFines] = useState([])
    const [fineTotal, setFineTotal] = useState(0)
    const [holds, setHolds] = useState([])
    const [holdCount, setHoldCount] = useState(0)
    // const [isLoading,setIsloading] = useState(false)
    // const [addFormShow, setAddFormShow] = useState(false)
    // const [updateAccount,setUpdateAccount] = useState(false)
    // const [deleteAccount,setDeleteAccount] = useState(false)
    const [showIndividual,setShowIndividual] = useState(true)
    const [renewModalShow, setRenewModalShow] = useState(false);
    const [renewMessage, setRenewMessage] = useState("");
    const [renewStatus, setRenewStatus] = useState("");
    const [cancelModalShow, setCancelModalShow] = useState(false);
    const [freezeModalShow, setFreezeModalShow] = useState(false);
    const [currentPatronID, setCurrentPatronID] = useState("");
    const [currentHoldID, setCurrentHoldID] = useState("");
    const [currentHoldFrozen, setCurrentHoldFrozen] = useState();
    const [expirationMessage, setExpirationMessage] = useState("");
    // const [editModalShow, setEditModalShow] = useState(false);
    // const [today] = useState(Date());
    const [expirationDate,setExpirationDate] = useState();
    const [expirationStatus, setExpirationStatus] = useState();

    // if()){
    //     setExpirationMessage("Expiring soon:")
    // }
    // else if (today > expires){
    //     setExpirationMessage("Expired on:")
    // }
    // else{
    //     setExpirationMessage("Expiration Date:")
    // }

    function ordinal(i) {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function Barcode()
    {
      const { inputRef } = useBarcode({
        value: [barcode],
        options: {
          background: "#fff",
          format: "codabar"
        }
      });
      
      return (
        <div className='barcodeDiv'>
          <img ref={inputRef} alt={barcode}/>
        </div>
      )
    }

    function BarcodeModal(props) {
        return (
          <Modal
            {...props}
            //size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="barcode-modal"
            centered
          >
            <Barcode />
          </Modal>
        );
    }
      
    function BarcodeTrigger() {
        const [modalShow, setModalShow] = React.useState(false);
        
        return (
            <>
            <Button variant="primary" size="sm" className="btn-full btn-account-top" onClick={() => {
                setModalShow(true);
                actionEvent('Show barcode', '/account'+(showIndividual?'Single':'All'));
            }}><FaBarcode /> Show barcode</Button>
        
            <BarcodeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            </>
        );
    }

    function Renew(patronId, checkoutId){
        return function(){
            actionEvent('Renew', '/account'+(showIndividual?'Single':'All'))
            console.log(patronId, checkoutId)
            fetch("https://www.icpl.org/catalog/app/renewReact.php?checkoutId="+checkoutId)
                .then(response => response.json())
                .then(data =>{
                    console.log(data)
                    if (data.hasOwnProperty('code')){
                        // example: {code: 134, specificCode: 0, httpStatus: 403, name: "WebPAC Error", description: "WebPAC Error :   TOO MANY RENEWALS"}
                        console.log(data.description)
                        let error = data.description
                        let reason = ''
                        if (error.indexOf("TOO MANY RENEWALS") > 0){
                            reason = "It has reached the limit of renewals.";
                        }
                        if (error.indexOf("You owe too much money") > 0){
                            reason = "Your account is over the fine limit.";
                        }
                        if (error.indexOf("Your registration has expired") > 0){
                            reason = "Your account has expired.";
                        }
                        if (error.indexOf("ON HOLD") > 0){
                            reason = "Another person has requested it.";
                        }
                        if (error.indexOf("TOO SOON TO RENEW") > 0){
                            reason = "It is too soon to renew.";
                        }

                        console.log("This cannot be renewed because:");
                        console.log(reason);
                        //modal saying the error in patron terms
                        setRenewStatus("Failed")
                        setRenewMessage(reason)
                        setRenewModalShow(true)
                        // alert("This item cannot be renewed.\r\n"+reason)
                    }
                    else{
                        console.log("Renewed")
                        console.log(data.dueDate)

                        //get localstorage with patronId
                        const patronRenew = JSON.parse(localStorage.getItem(patronId))
                        console.log(patronRenew.items)

                        //loop through items for this patron
                        function updateItem(item,index){
                            if (item.checkoutId === checkoutId){
                                console.log(item.checkoutId)
                                console.log(item.title)
                                patronRenew.items[index].duedate = data.dueDate
                            }
                        }
                        patronRenew.items.forEach(updateItem)

                        // //get localstorage with patronId
                        // const patronRenew = JSON.parse(localStorage.getItem(patronID))
                        // patronRenew.items[index].duedate = data.dueDate
                        // //update localstorage with new due date
                        localStorage.setItem(patronId, JSON.stringify(patronRenew));
                        // //cause rerender by updating paton
                        setPatron(patronRenew)

                        //modal saying success and new duedate
                        setRenewStatus("Success")
                        setRenewMessage(data.dueDate)
                        setRenewModalShow(true)
                        // alert(data.dueDate)
                    }                    
                })            
        }
    }

    function RenewModal(props) {
        if (renewStatus === 'Success'){
            if(renewModalShow){
                // ReactGA.modalview('/renewSuccess');
                actionStatusEvent('Renew success', '/account'+(showIndividual?'Single':'All'))
            }
            return (
                <Modal
                    {...props}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Success
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p>This item has been renewed.</p>
                    <p>It is now due <Moment format="MMM D, YYYY">{renewMessage}</Moment></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer>
                </Modal>

            );
        }
        else{
            if(renewModalShow){
                actionStatusEvent('Renew fail', '/account'+(showIndividual?'Single':'All'))
            }
            return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Unsuccessful
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>This item cannot be renewed.</p>
                <p>{renewMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            );
        }
      }

    function toggleFreeze(patronId, holdId, frozen){
        return function(){
            actionStatusEvent((frozen?"Unfreeze":"Freeze")+' confirmed', '/account'+(showIndividual?'Single':'All'))
            frozen = !frozen
            const url = "https://www.icpl.org/catalog/app/freeze.php?holdId="+holdId+"&freeze="+frozen
            console.log(url)
            fetch("https://www.icpl.org/catalog/app/freeze.php?holdId="+holdId+"&freeze="+frozen)
                .then(response => response.json())
                .then(data =>{ 
                    console.log("Frozen toggled")
                    //get localstorage with patronId
                    const patronFreeze = JSON.parse(localStorage.getItem(patronId))
                    console.log(patronFreeze.holds)

                    //loop through holds for this patron to find holdId
                    function updateFreeze(hold,index){
                        if (hold.id === holdId){
                            console.log(hold.id)
                            console.log(hold.title)
                            patronFreeze.holds[index].frozen = frozen
                        }
                    }
                    patronFreeze.holds.forEach(updateFreeze)

                    // patronFreeze.holds[index].frozen = frozen
                    // //update localstorage with new freeze status
                    localStorage.setItem(patronId, JSON.stringify(patronFreeze));
                    // //cause rerender by updating paton
                    setPatron(patronFreeze) 

                    //would prefer to use Alert or Modal but having trouble getting to work
                    setFreezeModalShow(false)
                    // let alertMsg
                    // if (frozen){
                    //     alertMsg = 'This hold is now frozen';
                    // } 
                    // else{
                    //     alertMsg = 'This hold is now unfrozen';
                    // }
                    // alert(alertMsg)
                })      
        }
    }

    function ShowFreezeModal(patronID, holdID, frozen){
        setCurrentPatronID(patronID)
        setCurrentHoldID(holdID)
        setCurrentHoldFrozen(frozen)
        setFreezeModalShow(true)
    }

    function FreezeModal(props) {
        let modalTitle = '';
        let modalBody = '';
        let modalButton = '';

        if (currentHoldFrozen === false){
            modalTitle = 'Freeze Hold?';
            modalBody = 'You are about to freeze this hold. This will suspend your hold while keeping your place in line.';
            modalButton = 'Freeze';
        }
        else{
            modalTitle = 'Unfreeze Hold?';
            modalBody = 'You are about to unfreeze this hold. This will reactivate your hold.';
            modalButton = 'Unfreeze' ;
        }

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalTitle}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={toggleFreeze(currentPatronID, currentHoldID, currentHoldFrozen)}>{modalButton}</Button>
                    <Button onClick={()=>{
                        setFreezeModalShow(false);
                        actionStatusEvent((currentHoldFrozen?"Unfreeze":"Freeze")+' canceled', '/account'+(showIndividual?'Single':'All'));
                    }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
      }

    function cancelHold(patronId, holdId){
        return function(){
            actionStatusEvent('Remove hold confirmed', '/account'+(showIndividual?'Single':'All'))
            const patronCancelHold = JSON.parse(localStorage.getItem(patronId))
            console.log('Before deleting in Sierra')
            console.log(patronCancelHold.holds)

            console.log(patronId)
            console.log(holdId)
            fetch("https://www.icpl.org/catalog/app/cancelHold.php?holdId="+holdId)
                .then(response => response.json())
                .then(data =>{ 
                    console.log("Cancelling Hold")
                    //get localstorage with patronId
                    const patronCancelHold = JSON.parse(localStorage.getItem(patronId))

                    //get details from accountReact.php
                    const patronData = '[{"name":"'+[patronCancelHold.name]+'","barcode": "'+[patronCancelHold.barcode]+'","hash": "'+[patronCancelHold.hash]+'"}]'
                    // console.log(patronData);
                    fetch("https://www.icpl.org/catalog/app/accountReact.php", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: (patronData),
                    })
                        .then(response => response.json())
                        .then(data =>{
                            if (data.authenticated === 'true'){
                                localStorage.setItem(data.patronID, JSON.stringify(data));
                                console.log(data)
                                setPatron(data)
                                setCancelModalShow(false)
                                // setRedirect(true)
                            }
                            else{
                              //show modal here or wait until accounts page?
        
                                // //add modal saying failed
                                // setModalShow(true)
                                // console.log(data)
                            }
                        })
        
            //  This was an attempt to delete the cancelled hold from local storage
            //  It works except puts "empty" where the hold was and then gets confused when the page rerenders 
            //  Above it is simply retrieving all of the patron data from accountReact.php and saving to local storage
            //  Works but takes a little longer so might want a spinner

            //         //loop through holds for this patron to find holdId
            //         function updateCancelHold(hold,index){
            //             if (hold.id === holdId){
            //                 console.log(hold.id)
            //                 console.log(hold.title)
            //                 //how do delete from the json?
            //                 // patronCancelHold.holds[index].frozen = frozen
            //                 delete patronCancelHold.holds[index]
            //             }
            //         }
            //         patronCancelHold.holds.forEach(updateCancelHold)
            //         console.log('After deleting from object')
            //         console.log(patronCancelHold.holds)
            // //         // patronFreeze.holds[index].frozen = frozen
            // //         // //update localstorage with new freeze status
            //         localStorage.setItem(patronId, JSON.stringify(patronCancelHold));
            // //         // //cause rerender by updating paton
            //         setPatron(patronCancelHold) 
            })      
        }
    }

    function ShowCancelModal(patronID, holdID){
        setCurrentPatronID(patronID)
        setCurrentHoldID(holdID)
        setCancelModalShow(true)
        // ReactGA.modalview('/cancelHold');
    }

    function CancelModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Remove Hold?
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>You are about to remove this hold.</p><p>Are you sure you no longer want to be on the waitlist for this item?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={cancelHold(currentPatronID, currentHoldID)}>Yes, remove hold</Button>
                    <Button onClick={()=>{
                        setCancelModalShow(false);
                        actionStatusEvent('Remove hold canceled', '/account'+(showIndividual?'Single':'All'));
                    }}>No, keep hold</Button>
                </Modal.Footer>
            </Modal>

        );
      }

      function EditModal(props) {
        return (
          <Modal
            {...props}

            // aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>Login failed</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>The library card and/or password are incorrect. Please try again.</p>
              <p>Need assistance? Call us at 319-356-5200 or <a href='https://www.icpl.org/services/ask'>email or chat</a> with us online.</p>
            </Modal.Body>

          </Modal>
        );
      }

    // function toggleAddForm(showing){
    //     // showing = !showing
    //     setAddFormShow(!showing)

    // }

    // //update button
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const patronData = '[{"name":"'+[name]+'","barcode": "'+[barcode]+'","password": "'+[pin]+'"}]'
    //     console.log(patronData);
    //     fetch("https://www.icpl.org/catalog/app/accountReact.php", {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: (patronData),
    //     })
    //         .then(response => response.json())
    //         .then(data =>{
    //             if (data.authenticated === 'true'){
    //                 localStorage.setItem(data.patronID, JSON.stringify(data));
    //                 console.log(data)
    //                 setUpdateAccount(true)
    //             }
    //             else{
    //                 console.log(editModalShow)
    //                 setEditModalShow(true)
    //                 console.log(editModalShow)
    //                 console.log(data)
    //             }
    //         })
    // }
    
    // //delete button
    // const handleDelete = (event) => {
    //     event.preventDefault()
    //     console.log("DELETED")
    //     setDeleteAccount(true)
    // }

    useEffect(() => {

        if (patronID === 'all'){
            //ReactGA.pageview('/accountAll')
            accountTypeEvent('All')

            function compareItems(a, b) {
                const itemA = a.duedate;
                const itemB = b.duedate;
              
                let comparison = 0;
                if (itemA > itemB) {
                  comparison = 1;
                } else if (itemA < itemB) {
                  comparison = -1;
                }
                return comparison;
            }

            function compareHolds(a, b) {
                const holdA = a.priority;
                const holdB = b.priority;
              
                let comparison = 0;
                if (holdA > holdB) {
                  comparison = 1;
                } else if (holdA < holdB) {
                  comparison = -1;
                }
                return comparison;
            }

            setShowIndividual(false)
            var itemsArray = []
            var holdsArray = []
            var finesArray = []
            var itemCountAll = 0
            var holdCountAll = 0
            var fineTotal = 0

            for(let i=0; i<localStorage.length; i++){
                const key = localStorage.key(i);
                if (!isNaN(key)){
                    // console.log(key)
                    var patron = JSON.parse(localStorage.getItem(key))
                    itemsArray = itemsArray.concat(patron.items)
                    // console.log(itemsArray)
                    finesArray = finesArray.concat(patron.fines)
                    holdsArray = holdsArray.concat(patron.holds)
                    itemCountAll += parseInt(patron.item_count)
                    holdCountAll += parseInt(patron.hold_count)
                    fineTotal += parseFloat(patron.fine_total)
                }
            }
            //resort items and holds now that merged
            itemsArray.sort(compareItems)
            holdsArray.sort(compareHolds)
            setName('All Accounts')
            setBarcode(null)
            setItems(itemsArray)
            setItemCount(itemCountAll)
            setFines(finesArray)
            setFineTotal(fineTotal)
            setHolds(holdsArray)            
            setHoldCount(holdCountAll)            
        }
        else{
            //ReactGA.pageview('/accountSingle')
            accountTypeEvent('Single')

            const patron = JSON.parse(localStorage.getItem(patronID))
            // console.log(patron)
            setName(patron.name)
            setBarcode(patron.barcode)
            setItems(patron.items)
            setFines(patron.fines)
            setHolds(patron.holds)
            setExpirationStatus(patron.expiration_status)
            setExpirationDate(patron.expiration)
        }

        // const patronData = '[{"name":"'+[name]+'","barcode": "'+[barcode]+'","password": "'+[pin]+'"}]'
        // // console.log(patronData);
        // fetch("https://www.icpl.org/catalog/app/accountReact.php", {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: (patronData),
        // })
        //     .then(response => response.json())
        //     .then(data =>{
        //         if (data.authenticated === "true"){
        //             //display details
        //             console.log("authenticated")
        //             console.log(data)
        //             setPatron(data)
        //             setItems(data.items)
        //             setFines(data.fines)
        //             setHolds(data.holds)
        //             setIsloading(false)
        //         }
        //         else{
        //             //add toast or popup
        //             console.log("failed")
        //             console.log(data)
        //             setIsloading(false)
        //         }
        //     })
    },[patron])
    // },[patron,addFormShow])

    // if(isLoading === true){
    //     //make spinner
    //     return(
    //         <div className="loading">
    //             <Spinner animation="border" role="status" variant="primary">
    //             <span className="sr-only">Loading...</span>
    //             </Spinner>
    //             {/* <img src={spinner} height="50px" width="50px" alt="Loading..." /> */}
    //         </div>
    //     )
    // }

    // if(deleteAccount === true){
    //     localStorage.removeItem(patronID)
    //     return <Redirect to="/accounts" />
    // }
    // else if (updateAccount === true){
    //     return <Redirect to="/accounts" />
    // }
    // else if(addFormShow === true){
    //     return(
    //         <div>
    //             <Navigation location='/account'/>
    //             <EditModal
    //                     show={editModalShow}
    //                     onHide={() => setEditModalShow(false)}
    //                 />
    //             <div className="accountForm">
    //                 <h1>Edit account</h1>
    //                 <Form onSubmit={handleSubmit}>
    //                     <Form.Group controlId="formName">
    //                         <Form.Label>Name this account</Form.Label>
    //                         <Form.Control 
    //                             type="text" 
    //                             value={name} 
    //                             name="patronname" 
    //                             onChange={e => setName(e.target.value)}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group controlId="formBarcode">
    //                         <Form.Label>Library Card Number</Form.Label>
    //                         <Form.Control 
    //                             type="text" 
    //                             value={barcode} 
    //                             placeholder="2197700..." 
    //                             name="barcode" 
    //                             onChange={e => setBarcode(e.target.value)}
    //                         />
    //                     </Form.Group>                
    //                     <Form.Group controlId="formPin">
    //                         <Form.Label>Password/PIN</Form.Label>
    //                         <Form.Control 
    //                             type="password" 
    //                             value={pin} 
    //                             name="pin" 
    //                             onChange={e => setPin(e.target.value)}
    //                         />
    //                     </Form.Group>
    //                     <Button variant="success" type="submit" onClick={()=>{ReactGA.modalview('/editUpdate')}}>Update</Button>
    //                 </Form> 
    //                 {/* make a separate form for deleting*/}
    //                 <Form onSubmit={handleDelete}>
    //                     <Button className="link-danger" variant="danger" type="submit"  onClick={()=>{ReactGA.modalview('/editDelete')}}>Delete</Button>
    //                 </Form>
    //                 <Navbar fixed="bottom" bg="primary" className="justify-content-center" onClick={()=>{toggleAddForm(addFormShow)}}>
    //                     Cancel
    //                 </Navbar>
    //             </div>
    //         </div>
    //     )
    // }
    // else{
        return(
            <div>
                <Navigation location='/account'/>
                <div className="accountMain">
                    <Container fluid>
                    {showIndividual && (
                        <Row>
                            <Col xs={7}>
                                <h1>{name}</h1>
                                <div>{barcode}</div>
                                {expirationStatus == "expired" ? <div><span className="text-danger">Expired: <Moment format="MMM D, YYYY">{expirationDate}</Moment></span></div> : ""}
                                {expirationStatus == "soon" ? <div><span className="text-danger">Expires: <Moment format="MMM D, YYYY">{expirationDate}</Moment></span></div> : ""}
                                {expirationStatus == "not" ? <div className="not-expiring">Expires: <Moment format="MMM D, YYYY">{expirationDate}</Moment></div> : ""}
                            </Col>
                                <Col xs={5}>
                                    <BarcodeTrigger/>
                                    <Link class="edit-account btn btn-default btn-sm btn-account-top" to={{pathname: './editAccount',editProps: {patronID: patronID}}}><FaPencilAlt /> Edit</Link>
                                </Col>
                        </Row>
                    )}
                    {!showIndividual && (
                        <Row>
                            <Col xs={12}>
                                <h1>{name}</h1>
                            </Col>
                        </Row>
                    )}
                    </Container>

                    <div className="questions">
                        <p>Questions about your account?</p>
                        <Container fluid>
                            <Row>
                                <Col xs={4}>
                                    <a className="display-block" href="tel:3193565200"><Button variant="default" size="sm" className="btn-full" onClick={()=>contactEvent('Call', '/account'+(showIndividual?'Single':'All'))}><IoIosCall /> Call</Button></a>
                                </Col>
                                <Col xs={4} style={{paddingRight:'5px',paddingLeft:'5px'}}>
                                    <a href="mailto:libraryaccountnotice@icpl.org"><Button variant="default" size="sm" className="btn-full" onClick={()=>contactEvent('Email', '/account'+(showIndividual?'Single':'All'))}><MdEmail /> Email</Button></a>
                                </Col>
                                <Col xs={4}>
                                    <a href="https://www.icpl.org/services/ask#chat"><Button variant="default" size="sm" className="btn-full" onClick={()=>contactEvent('Chat', '/account'+(showIndividual?'Single':'All'))}><IoIosChatbubbles /> Chat</Button></a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    
                    {/* <Link key={patron.id} to={{
                            pathname: './barcode',
                            barcodeProps: {
                                barcode: patron.barcode
                            }
                        }}
                    >
                        <Button variant="primary"><FaBarcode /> Barcode page</Button>
                    </Link> */}

                    <Accordion defaultActiveKey="0" className="borrowedAccordion">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            Borrowed items <span className="acctTotal">({patronID === 'all' ? itemCount : patron.item_count})</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <div>
                                    <ListGroup variant="flush">
                                        {items.map((item, index) => (
                                            <ListGroup.Item className={item.dueColor} key={item.id}>
                                                <div className="item-title"><strong>{item.title}</strong></div>
                                                {item.author.length > 0 && <div className="item-author">By {item.author}</div>}
                                                <div className="item-call-number">Call number: {item.callNumber}</div>
                                                <div className="due-date">Due <Moment format="MMM D, YYYY">{item.duedate}</Moment></div>
                                                <Button style={{"marginTop":5}} variant="primary" size="sm" onClick={Renew(item.patronID, item.checkoutId, index)}>Renew</Button>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    {( (items.length === 0) ? <Card.Body>You have no borrowed items.</Card.Body> : "" )}
                                </div>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                    <Accordion defaultActiveKey="1" className="finesAccordion">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                            Fines <span className="acctTotal">(${patronID === 'all' ? fineTotal.toFixed(2) : patron.fine_total})</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <div>
                                    <ListGroup variant="flush">
                                        {fines.map((fine, index) => (
                                            <ListGroup.Item className="accountCard" key={fine.id}>
                                                <strong>{fine.title}</strong><br />
                                                Amount: ${fine.amount}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    {( (fines.length === 0)
                                        ? <Card.Body>You have no fines.</Card.Body>
                                        : <Card.Footer><p className="fines-note"><em><a href="https://search.icpl.org/MyResearch/Fines">Pay fines</a> in the catalog.</em></p></Card.Footer>
                                        )}
                                </div>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                    <Accordion defaultActiveKey="2" className="holdsAccordion">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                            Holds <span className="acctTotal">({patronID === 'all' ? holdCount : patron.hold_count})</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <div>
                                    <ListGroup variant="flush">
                                        {holds.map((hold, index) => (
                                        <ListGroup.Item className={(hold.frozen ? "holdItem frozen" : "holdItem") + (hold.priority ? '': ' available')} key={hold.id}>
                                            <div className="holdDiv" >
                                                <div className="item-title"><strong>{hold.title}</strong></div>
                                                {hold.author.length > 0 && <div className="item-author">By {hold.author}<br /></div>}
                                                <div className="hold-position">{hold.priority ? "You are "+ordinal(hold.priority)+" in line" : ""}</div>
                                                <div className="pickup-location">{hold.priority ? "" : "Pickup location: "+hold.pickupLocation}</div>
                                                <div className="pickup-ready text-success">{hold.priority ? "" : "Ready for pickup until "+hold.pickupByDate}</div>
                                                {/* {hold.priority ? "Position: "+hold.priority : "Ready to pick up"}<br /> */}
                                                <div className="hold-btns">
                                                    <Button variant="primary" size="sm" style={{ marginRight: 5, display: hold.priority ? 'inline': 'none'}} onClick={() => {
                                                        ShowFreezeModal(hold.patronID, hold.id, hold.frozen);
                                                        actionEvent((hold.frozen ? "Unfreeze" : "Freeze"), '/account'+(showIndividual?'Single':'All'))
                                                    }}>{hold.frozen ? "Unfreeze" : "Freeze"}</Button>
                                                {/* <Button variant="primary" size="sm" style={{ display: hold.priority ? 'inline': 'none'}} onClick={toggleFreeze(hold.patronID, hold.id, hold.frozen)}>{hold.frozen === true ? "Unfreeze" : "Freeze"}</Button> */}
                                                <Button variant="danger" size="sm" onClick={() => {
                                                    ShowCancelModal(hold.patronID, hold.id);
                                                    actionEvent('Remove hold', '/account'+(showIndividual?'Single':'All'));
                                                    }}>Remove hold</Button></div>
                                                {/* <Button variant="danger" size="sm" onClick={cancelHold(hold.patronID, hold.id)}>Cancel Hold</Button> */}
                                            </div>
                                            {hold.frozen === true ? <div className="holdSnowflake"><IoIosSnow/></div> : ""}
                                            {hold.priority === 0 ? <div className="holdBell"><FaBell/></div> : ""}
                                        </ListGroup.Item>
                                    ))}
                                    </ListGroup>
                                    {( (holds.length === 0) ? <Card.Body>You have no items on hold.</Card.Body> : "" )}
                                </div>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                    <RenewModal
                        show={renewModalShow}
                        onHide={() => setRenewModalShow(false)}
                    />

                    <FreezeModal
                        show={freezeModalShow}
                        onHide={() => setFreezeModalShow(false)}
                    />

                    <CancelModal
                        show={cancelModalShow}
                        onHide={() => setCancelModalShow(false)}
                    />

                </div>
            </div>
        )
    // }
}
