import { unstable_createMuiStrictModeTheme } from '@material-ui/core';
import React, {useState, useEffect}from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Navigation from './Navigation.js'
import {Redirect} from 'react-router-dom'
import './Settings.css';
import ReactGA from 'react-ga4'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import metadata from '../metadata.json'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import { ToggleButton } from 'react-bootstrap'

export default function Settings(){
    function themeEvent(linkText){
      ReactGA.event('theme_changed', {
        link_text: linkText,
      });
    };
    function dataEvent(linkText){
      ReactGA.event('data_cleared', {
        link_text: linkText,
      });
    };

    console.log("Settings reloaded")
    
    const [themeSetting, setThemeSetting] = useState([])
    const [storage, setStorage] = useState('')
    const [settingModalShow, setSettingModalShow] = useState(false);
    const [themeModalShow, setThemeModalShow] = useState(false);
    // const [token, setToken] = useState('')

    function delLocalStorage(key){
        if (key === 'events' || key === 'posts' || key === 'holidays'){
            console.log('events, posts or holidays')
            localStorage.removeItem(key);
        }
        else if(key === 'everything'){
            localStorage.clear()
        }
        else{
            console.log('accounts')
            for(let i=0; i<localStorage.length; i++){
                let accountkey = localStorage.key(i)
                if (accountkey !== 'events' && accountkey !== 'holidays' && accountkey !== 'posts' && accountkey !== 'theme' && accountkey !== 'currPatron' && accountkey !== 'currPost' && accountkey !== 'currEvent'){
                    localStorage.removeItem(accountkey);
                }
            }
        }
        setStorage(key)
        setSettingModalShow(true)
    }

    function sendLocalStorage(){
      var keys = Object.keys(localStorage);
      var values = [];
      var i = keys.length;
      localStorage.removeItem('msgToken');
      localStorage.removeItem('events');
      localStorage.removeItem('posts');
      localStorage.removeItem('theme');
      localStorage.removeItem('holidays');
      localStorage.removeItem('currEvent');
      localStorage.removeItem('currPost');
      localStorage.removeItem('currPatron');
      // console.log("localStorage");
      // console.log(JSON.stringify(localStorage));

      // while(i--){
      //   var storageKey = keys[i];
      //   if (storageKey !== 'events' && storageKey !== 'holidays' && storageKey !== 'posts' && storageKey !== 'theme' && storageKey !== 'currPatron' && storageKey !== 'currPost' && storageKey !== 'currEvent'){
      //     // var jsonStorage = localStorage.getItem(storageKey)
      //     // console.log(storageKey);
      //     // console.log(JSON.parse(localStorage.getItem(storageKey)));
      //     values[i] = JSON.parse(localStorage.getItem(storageKey));
      //   }
      // }
      // var jsonLocalStorage = JSON.stringify(values);

      fetch("https://www.icpl.org/catalog/app/sendLocalStorage.php", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: (JSON.stringify(localStorage)),
        // body: (jsonLocalStorage),
      })
        .then(response => response.json())
        .then(data =>{
            if (data.localStorage === 'true'){
              alert("Sent");
            }
            else{
              alert("Failed");
            }
      })
    }

  function changeTheme(theme){
        setThemeSetting(theme)
        setThemeModalShow(true)
        localStorage.setItem('theme',theme);
    }

    function ThemesModal(props) {
        return (
          <Modal
            {...props}

            // aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>Theme changed</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>Your theme has been changed to {themeSetting}. Refresh this page or load another for changes to take effect.</p>
            </Modal.Body>
          </Modal>
        );
      }

      
    function SettingsModal(props) {
        return (
          <Modal
            {...props}

            // aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>Local Storage Updated</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>You deleted {storage} from local storage.</p>
            </Modal.Body>
          </Modal>
        );
      }

      function ThemeChangerGroup(props) {
        //const [themeSetting, setThemeSetting] = useState([])
        //console.log(localStorage.theme)
        return (
          <ToggleButtonGroup type="checkbox" value={localStorage.theme} onChange={changeTheme}>
            <ToggleButton value="light" onClick={()=>{
                changeTheme('light');
                themeEvent('Light');
            }}>Light</ToggleButton>
            <ToggleButton value="dark" onClick={()=>{
                changeTheme('dark');
                themeEvent('Dark');
            }}>Dark</ToggleButton>
          </ToggleButtonGroup>
        );
      }

    return(
        <div>
            <Navigation location='/settings'/>
            <div className="settingsMain">
                <h1>Settings</h1>
                <h2 className="first">Delete app data</h2>
                <p><em>This will clear your saved accounts and the other data stored in the app.</em></p>
                {/* <Button variant="primary" size="sm" onClick={()=>{delLocalStorage('posts');dataEvent('Delete data')}}>Posts</Button> */}
                {/* <Button variant="primary" size="sm" onClick={()=>{delLocalStorage('events');dataEvent('Delete data')}}>Events</Button> */}
                {/* <Button variant="primary" size="sm" onClick={()=>{delLocalStorage('holidays');dataEvent('Delete data')}}>Holidays</Button> */}
                {/* <Button variant="primary" size="sm" onClick={()=>{delLocalStorage('accounts');dataEvent('Delete data')}}>Accounts</Button> */}
                <Button variant="danger" block onClick={()=>{delLocalStorage('everything');dataEvent('Delete data')}}>Delete data</Button>

                <h2>Change theme</h2>
                <p><em>Refresh to see the change.</em></p>
                <ThemeChangerGroup />

                <h2>App details</h2>
                {/* <p><a href="https://www.icpl.org/myicpl"><BsFillInfoCircleFill/> App how-to</a></p> */}
                <p className="version">{`version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</p>
                {/* <Button variant='white' style={{color: '#FFF'}} onClick={()=>{sendLocalStorage()}}>Send data</Button> */}
                {/* <Button variant="danger" block onClick={()=>{sendLocalStorage()}}>Send data</Button> */}

                {/* <h2>Notifications Token</h2>
                <div id='tokenDiv'>
                    {token}
                </div> */}
            </div>

            <ThemesModal
                show={themeModalShow}
                onHide={() => setThemeModalShow(false)}
            />

            <SettingsModal
                show={settingModalShow}
                onHide={() => setSettingModalShow(false)}
            />

        </div>
    )
}