import './Search.css';
import React, {useState, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Navigation from './Navigation.js'
import ReactGA from 'react-ga4'

export default function Search() {

    const [searchTerms, setSearchTerms] = useState("")

    function Event(linkText){
        ReactGA.event('search_click', {
          link_text: linkText,
        });
    };

    const handleSubmit = (event) => {
        Event('Search')

        event.preventDefault();
        // const url = 'https://catpro.icpl.org/iii/encore/Home,$Search.form.sdirect?formids=target&lang=eng&suite=def&reservedids=lang,suite&submitmode=&submitname=&target='+searchTerms;
        let searchTerms = event.target.elements.searchTerms.value;
        const url = 'https://search.icpl.org/Search/Results?lookfor='+searchTerms+'&type=AllFields&limit=20';
        window.open(url,'_blank')
    }

    return(
        <div>
            <Navigation location='/search'/>
            <div className="searchMain">
                <Form onSubmit={handleSubmit} id="searchCatalog">
                    <Form.Group controlId="searchTermsField">
                        <Form.Label as="h1">Search</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Search for books, movies, and more"
                            name="searchTerms"
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="btn-full">Search</Button>
                </Form>
            </div>
        </div>
    )
}